<router>
{
    name: 'Flow Analytics',
}
</router>
<template lang="pug">

v-container(class="flow-analytics-main").mt-8
    v-row( justify='center')
        v-col.d-flex.flex-wrap
            .text-h4.brand--text Flow Analytics
    v-row(v-if="errorMessage")
        v-col.d-flex.justify-center
            v-alert(type="error" dismissible class="my-4")
                span {{ errorMessage }}
    v-row(justify='center')
        v-col(cols='8' class='d-flex')
            v-spacer
            .text-h5.brand--text Select System
                v-select(
                    v-model="systemId",
                    :items="systems",
                    item-text="system_name",
                    item-value="id",
                    outlined
                    light
                    :menu-props="{ contentClass: 'custom-menu' }"
                    class="always-outline"
                )
            v-spacer
    v-row(justify='center' v-if='noDataForSystem')
        v-col(cols='8' class='d-flex')
            v-spacer
            v-card(color='error', )
                v-card-title No data found for this system
            v-spacer
    v-row(v-if="isInitialized")
        v-col(justify='left', cols='9')
            FlowChart(
                :seriesData="charts['Today'].seriesData",
                :name="charts['Today'].name",
                :productionVolume="charts['Today'].productionVolume",
                :productionTime="charts['Today'].productionTime",
                :startDate="chartDate(charts['Today'].startDate)"
            )
        v-spacer
        v-col(justify='right', cols='3')
            v-card(light, color="#e5e5e5")
                v-card-title Times to Meet Demand
                v-card-text


    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['2 Days Ago'].seriesData",
                :name="charts['2 Days Ago'].name"
                :productionVolume="charts['2 Days Ago'].productionVolume",
                :productionTime="charts['2 Days Ago'].productionTime",
                :startDate="chartDate(charts['2 Days Ago'].startDate)",
                :isHistorical="true"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['Yesterday'].seriesData",
                :name="charts['Yesterday'].name",
                :productionVolume="charts['Yesterday'].productionVolume",
                :productionTime="charts['Yesterday'].productionTime",
                :startDate="chartDate(charts['Yesterday'].startDate)",
                :isHistorical="true"
            )
    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['Tomorrow'].seriesData",
                :name="charts['Tomorrow'].name",
                :productionVolume="charts['Tomorrow'].productionVolume",
                :productionTime="charts['Tomorrow'].productionTime"
                :startDate="chartDate(charts['Tomorrow'].startDate)"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['2 Days From Today'].seriesData",
                :name="charts['2 Days From Today'].name",
                :productionVolume="charts['2 Days From Today'].productionVolume",
                :productionTime="charts['2 Days From Today'].productionTime",
                :startDate="chartDate(charts['2 Days From Today'].startDate)"
            )
    v-row(v-if="isInitialized")
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['3 Days From Today'].seriesData",
                :name="charts['3 Days From Today'].name",
                :productionVolume="charts['3 Days From Today'].productionVolume",
                :productionTime="charts['3 Days From Today'].productionTime",
                :startDate="chartDate(charts['3 Days From Today'].startDate)"
            )
        v-col(cols='6')
            FlowChart(
                :seriesData="charts['4 Days From Today'].seriesData",
                :name="charts['4 Days From Today'].name",
                :productionVolume="charts['4 Days From Today'].productionVolume",
                :productionTime="charts['4 Days From Today'].productionTime",
                :startDate="chartDate(charts['4 Days From Today'].startDate)"
            )


</template>

<script lang="js">

import FlowChart from '@/components/portal/analytics/FlowChart';
import moment from 'moment';

export default {
    // extends: dashboard,
    components: {
        FlowChart,
    },
    emits: ['getFlowData'],
    data () {
        return {
            charts: {},
            selectedCustomer: null,
            chartNames: [
                '2 Days Ago', 'Yesterday', 'Today', 'Tomorrow', '2 Days From Today', '3 Days From Today', '4 Days From Today'
            ],
            dataSources: [
                'Production', 'Demand', 'Storage', 'Consumption'
            ],
            customerId: null,
            isFetching: false,
            isInitialized: false,
            selectedOption: 'model',
            selectedDate: null,
            errorMessage: '',
            showDatePicker: false,
            internalSystemId: null,
            noDataForSystem: false,
        };
    },
    computed: {
        currentCustomerId () {
            return this.$store.state.customerId;
        },
        formattedSelectedDate () {
            return this.selectedDate ? moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        },
        systems: {
            get () {
                return this.$store.state.analyticsConfig;
            },
        },
        systemId: {
            get () {
                return this.internalSystemId !== null
                    ? this.internalSystemId
                    : this.systems.length > 0
                        ? this.systems[0].id
                        : null;
            },
            set (value) {
                console.log('systemId set', value);
                this.internalSystemId = value;
                if (value) {
                    this.getData();
                }
            }
        },
    },
    watch: {
        internalSystemId (newVal, oldVal) {
            console.log('internalSystemId changed', newVal, oldVal);
            if (newVal !== oldVal) {
                this.isInitialized = false;
            }
        },
    },
    methods: {
        init () {
            if(!this.$store.state.customerId){
                this.$router.push('/customer/analytics');
                return;
            }
            if(this.systems.length > 0){
                this.internalSystemId = this.systems[0].id;
                this.getData();
            }
        },

        getData () {
            console.log('getData fired',this.internalSystemId, this.systemId);
            // fetch data from API or websocket
            if (this.isFetching) return;
            this.noDataForSystem = false;
            this.isFetching = true;
            this.customerId = this.currentCustomerId;
            console.log('getData fired',this.customerId);


            this.socketEmit('getFlowData', {
                systemId: this.systemId
            }, result => {
                this.isFetching = false;
                console.log('getFlowData result', result);
                if(result.error){
                    console.log(result.error)
                    if(result.error === 'no data'){
                        this.noDataForSystem = true;
                        return;
                    } else if(result.error == 'not feasible'){
                        this.errorMessage = `Optimization not possible for this system. \n Check the system configuration and try again.`;
                        return;
                    } else {
                        this.errorMessage = `Error: ${result.error}`;
                        console.error('Failed to fetch data:', result.error);
                        return;
                    }
                }
                this.errorMessage = '';
                result.data.forEach(chart => {
                    // loop through the chart.seriesData to apply the yAxis and step properties
                    chart.seriesData.forEach(series => {
                        if (series.name === 'storage') {
                            series.yAxis = 1;
                        } else if (series.name === 'consumption') {
                            series.yAxis = 1;
                            series.visible = false;
                        } else if (series.name === 'production') {
                            series.yAxis = 0;
                            series.step = 'center';
                        } else {
                            series.yAxis = 0;
                        }
                    });
                    this.$set(
                        this.charts,
                        chart.name,
                        chart
                    );
                });
                this.isInitialized = true;
            });

        },
        chartDate (strDate) {
            const dt = moment.utc(strDate, 'YYYY-MM-DD');
            const dtNumeric = dt.format('YYYY-MM-DD');
            const dtDay = dt.format('dddd');
            return `<b>${dtNumeric} &raquo; <b>${dtDay}</b>`;
        }
    },
    mounted () {
        this.init();
        console.log('FlowAnalytics mounted');
    },
    created () {
        this.$store.dispatch('connectSocket');
    }
};
</script>

<style scoped>
    .flow-analytics-main {
        max-width: 90%;
    }

    .custom-menu .v-select-list {
        color: #443e3e !important; /* List item text color */
        background-color: rgb(0,53,92) !important; /* Dark background color of menu edges */
    }

    .always-outline >>> .v-input__slot {
        border: 1px solid #1976D2 !important; /* Replace with your desired color */
    }

    .always-outline >>> .v-input__slot:hover {
        border: 1px solid #115293 !important; /* Optional: Change on hover */
    }



</style>
