import { render, staticRenderFns } from "./flow.vue?vue&type=template&id=31da197a&scoped=true&lang=pug"
import script from "./flow.vue?vue&type=script&lang=js"
export * from "./flow.vue?vue&type=script&lang=js"
import style0 from "./flow.vue?vue&type=style&index=0&id=31da197a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31da197a",
  null
  
)

/* custom blocks */
import block0 from "./flow.vue?vue&type=custom&index=0&blockType=router"
if (typeof block0 === 'function') block0(component)

export default component.exports